import React, { useState, useEffect, useContext, useRef } from 'react';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { fetchDataAgnostic, postDataAgnostic } from '../../../../../../Services/dataApi';
import { v4 as uuidv4 } from 'uuid';
import { useAlert } from 'react-alert';

import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { formatBearerToken } from '../../../../../../Common.functions';

import moment from 'moment';
import style from './Waitlist.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Input, Label, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { clone } from '../../../../../../Services/schedule';
import { RouterPrompt } from '../../../../Common/RouterPrompt/RouterPrompt';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

const Waitlist = ({
  selectedCandidate,
  saveResults,
  setChanges,
  changes,
  resetFunctions,
  setResetFunctions,
  getCandidateDetails,
  // abortController,
}) => {
  const [error, setError] = useState(false);
  const [dates, setDates] = useState([]);
  const datesReference = useRef();

  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState();

  const [isWaitlisted, setIsWaitlisted] = useState(false);
  const [unSelectedDates, setUnSelectedDates] = useState([]);
  const [waitlistData, setWaitlistData] = useState({});
  const [waitlistDataCopy, setWaitlistDataCopy] = useState({});
  const [mySelectedCandidate, setMySelectedCandidate] = useState(); // used for comparing pks, if we need to refetch inteviewDates
  const [messages, setMessages] = useState([]);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const alert = useAlert();

  useEffect(() => {
    if (
      selectedCandidate != null &&
      (mySelectedCandidate == null ||
        mySelectedCandidate.pk_Candidate != selectedCandidate.pk_Candidate ||
        mySelectedCandidate.pk_InterviewDate != selectedCandidate.pk_InterviewDate)
    ) {
      if (abortController) {
        abortController.abort();
      }
      const newController = new AbortController();
      setAbortController(newController);
      // setTimeout(() => {
      getInterviewDates(null, selectedCandidate, { abortController: newController });
      // }, 500);

      setMySelectedCandidate(selectedCandidate);
      const newChanges = clone(changes);
      newChanges.waitlist = false;
      setChanges(newChanges);
    }
  }, [selectedCandidate]);

  useEffect(() => {
    if (dates && datesReference.current && dates.length == datesReference.current.length) {
      checkChanges(dates);
    }
  }, [dates, datesReference]);

  useEffect(() => {
    if (resetFunctions) {
      if (resetFunctions.current == null) {
        resetFunctions.current = {};
      }
      if (resetFunctions.current.waitlist == null) {
        resetFunctions.current.waitlist = discardChanges;
      }
    }
  }, [resetFunctions]);

  const discardChanges = () => {
    setDates(clone(datesReference.current));
  };

  const checkChanges = (newData) => {
    const fieldsToCheck = ['PreferenceOrder', 'isWaitlistItem'];
    let hasChanges = false;
    fieldsToCheck.forEach((key) => {
      newData.forEach((newD) => {
        const dateReference = datesReference.current.find((dr) => {
          return dr.pk_InterviewDate == newD.pk_InterviewDate;
        });

        if (dateReference == null) {
          hasChanges = true;
        }

        if (newD[key] != null && dateReference != null && newD[key] != dateReference[key]) {
          hasChanges = true;
        }
      });
    });
    const newChanges = clone(changes);

    newChanges.waitlist = hasChanges;
    setChanges(newChanges);
  };

  // TODO: make key value pairs like waitListClone
  const getInterviewDates = (options) => {
    const { callback, abortController } = options || {};

    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/candidate/interviewDates',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_Candidate: selectedCandidate.pk_Candidate,
          },
          formatBearerToken(token),
          { abortController: abortController },
        )
          .then((res) => {
            prepareDatesForDisplay(res.data);
            setAbortController(null);
            if (callback) {
              callback();
            }
          })
          .catch((err) => {
            setAbortController(null);
          });
      })
      .catch((err) => {
        setAbortController(null);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addDateToWaitlist = (pk_InterviewDate) => {
    const newWaitlistDataCopy = clone(waitlistDataCopy);
    const newDates = clone(dates);
    let dateIndex = dates.findIndex((d) => {
      return d.pk_InterviewDate == pk_InterviewDate;
    });

    let dateToUpdate = dates[dateIndex];

    let existingItemsCount = 0;
    newDates.forEach((date) => {
      if (date.Removed === false) {
        existingItemsCount += 1;
      }
    });

    // if (!newWaitlistDataCopy[pk_InterviewDate]) {
    // const itemActual =
    //   dates.find((d) => {
    //     return d.pk_InterviewDate == pk_InterviewDate;
    //   }) || {};

    dateToUpdate = {
      ...dateToUpdate,
      PreferenceOrder: existingItemsCount,
      Removed: false,
      isWaitlistItem: true,
      fk_Candidate: selectedCandidate.pk_Candidate,
      fk_InterviewDate: pk_InterviewDate,
      UUID: uuidv4(),
    };
    // } else {
    //   dateToUpdate.isWaitlistItem = true;
    //   dateToUpdate.Removed = false;
    //   dateToUpdate.PreferenceOrder = existingItemsCount;
    // }

    newDates[dateIndex] = dateToUpdate;
    const waitListDates = newDates.filter((d) => {
      return d.isWaitlistItem;
    });
    const nonWaitListDates = newDates.filter((d) => {
      return !d.isWaitlistItem;
    });

    waitListDates.forEach((item, i) => {
      if (item.isWaitlistItem) {
        item.PreferenceOrder = i;
      }
    });

    nonWaitListDates.sort((a, b) => {
      const aMoment = moment(a.DateOfInterview);
      const bMoment = moment(b.DateOfInterview);

      if (aMoment.isBefore(bMoment)) {
        return -1;
      } else if (aMoment.isBefore(bMoment)) {
        return 1;
      } else {
        return 0;
      }
    });

    newDates
      .sort((a, b) => {
        // need to move removed toward the end
        if (a.isWaitlistItem && !b.isWaitlistItem) {
          return -1;
        }
        if (a.isWaitlistItem && b.isWaitlistItem) {
          return 0;
        }
        if (!a.isWaitlistItem && b.isWaitlistItem) {
          return 1;
        }
        return a.PreferenceOrder > b.PreferenceOrder ? 1 : -1;
      })
      .forEach((item, i) => {
        const { pk_InterviewDate } = item;
        if (item.isWaitlistItem) {
          item.PreferenceOrder = i;
        }
      });

    setDates([...waitListDates, ...nonWaitListDates]);
    // setDates(newDates);

    setWaitlistDataCopy(newWaitlistDataCopy);
  };

  const removeDateFromWaitlist = (pk_InterviewDate) => {
    const newDates = clone(dates);
    const dateToUpdateIndex = newDates.findIndex((d) => {
      return d.pk_InterviewDate == pk_InterviewDate;
    });
    const dateToUpdate = newDates[dateToUpdateIndex];

    dateToUpdate.Removed = true;
    dateToUpdate.isWaitlistItem = false;

    newDates[dateToUpdateIndex] = dateToUpdate;

    const waitListDates = newDates.filter((d) => {
      return d.isWaitlistItem;
    });
    const nonWaitListDates = newDates.filter((d) => {
      return !d.isWaitlistItem;
    });

    waitListDates.forEach((item, i) => {
      if (item.isWaitlistItem) {
        item.PreferenceOrder = i;
      }
    });

    nonWaitListDates.sort((a, b) => {
      const aMoment = moment(a.DateOfInterview);
      const bMoment = moment(b.DateOfInterview);

      if (aMoment.isBefore(bMoment)) {
        return -1;
      } else if (aMoment.isBefore(bMoment)) {
        return 1;
      } else {
        return 0;
      }
    });

    newDates
      .sort((a, b) => {
        // need to move removed toward the end
        if (a.isWaitlistItem && !b.isWaitlistItem) {
          return -1;
        }
        if (a.isWaitlistItem && b.isWaitlistItem) {
          return 0;
        }
        if (!a.isWaitlistItem && b.isWaitlistItem) {
          return 1;
        }
        return a.PreferenceOrder > b.PreferenceOrder ? 1 : -1;
      })

      .forEach((item, i) => {
        const { pk_InterviewDate, isWaitlistItem } = item;
        if (isWaitlistItem) {
          item.PreferenceOrder = i;
        } else {
          delete item.PreferenceOrder;
        }
      });

    setDates([...waitListDates, ...nonWaitListDates]);
    // setDates(newDates);
  };

  const renderHistory = (history = []) => {
    const deciferText = (item) => {
      let usersName = '';

      if (item.AddedByUser1Candidate2 === 1) {
        usersName = `${item.UserFirst} ${item.UserLast}`;
      } else if (item.IsAutomation) {
        usersName = 'Automation';
      } else {
        usersName = `${item.FirstName} ${item.LastName}`;
      }

      if (item.RegisteredState && item.RegisteredState > 0) {
        if (item.RegisteredState === 1) {
          return 'Interview Date Registered by ' + usersName;
        } else if (item.RegisteredState === 2) {
          return 'Interview Date Unregistered by ' + usersName;
        }
      } else {
        if (item.Added1Removed2 === 2) {
          return 'Date removed by ' + usersName;
        } else if (item.Added1Removed2 === 1) {
          // ${item.fk_InterviewDate}:
          return `Date added into position ${item.PositionChange + 1} by ${usersName}`;
        } else if (item.PositionChange !== null) {
          return `Preference Order changed from ${item.originalPreferenceOrder || '??'} to ${
            item.PositionChange ? item.PositionChange + 1 : '??'
          } by ${usersName}`;
        }
      }
    };
    // return <div></div>;
    return history.map((item) => {
      const { createdAt, pk_InterviewDate } = item;
      return (
        <div key={'waitlsitItem_' + pk_InterviewDate} style={{ marginBottom: 15 }}>
          <b>{moment(createdAt).format('MMM DD, YYYY hh:mm A')}</b> <br />
          {deciferText(item)}
        </div>
      );
    });
  };

  const changeSortOrder = (value, id) => {
    let numberValue = Number(value) - 1;

    const newDates = clone(dates);
    const indexToUpdate = newDates.findIndex((d) => {
      return d.pk_InterviewDate == id;
    });
    const indexToMoveTo = numberValue;
    const item1 = newDates[indexToUpdate];
    const item2 = newDates[indexToMoveTo];
    item1.PreferenceOrder = indexToMoveTo;
    item2.PreferenceOrder = indexToUpdate;
    newDates[indexToMoveTo] = item1;
    newDates[indexToUpdate] = item2;
    setDates(clone(newDates));
    return;
  };

  const prepareDatesForDisplay = (rawDates) => {
    const waitListDates = rawDates.filter((d) => {
      return d.isWaitlistItem;
    });

    waitListDates
      .sort((a, b) => {
        return a.PreferenceOrder - b.PreferenceOrder;
      })
      .sort((a, b) => {
        const aMoment = moment(a.DateOfInterview);
        const bMoment = moment(b.DateOfInterview);

        if (aMoment.isBefore(bMoment)) {
          return 1;
        } else if (aMoment.isBefore(bMoment)) {
          return -1;
        } else {
          return 0;
        }
      });

    const nonWaitListDates = rawDates.filter((d) => {
      return !d.isWaitlistItem;
    });

    nonWaitListDates
      .sort((a, b) => {
        return a.PreferenceOrder - b.PreferenceOrder;
      })
      .sort((a, b) => {
        const aMoment = moment(a.DateOfInterview);
        const bMoment = moment(b.DateOfInterview);

        if (aMoment.isBefore(bMoment)) {
          return 1;
        } else if (aMoment.isBefore(bMoment)) {
          return -1;
        } else {
          return 0;
        }
      })
      .forEach((d) => {
        d.Removed = true;
      });

    const sortedDates = [...waitListDates, ...nonWaitListDates];
    const newWaitlistDataCopy = {};
    waitListDates.forEach((d) => {
      d.originalPreferenceOrder = d.PreferenceOrder;
      d.originalRemovedState = d.Removed;
      const key = d.pk_InterviewDate;
      newWaitlistDataCopy[key] = d;
    });

    const newChanges = clone(changes);
    newChanges.waitlist = false;
    setChanges(newChanges);
    setDates(sortedDates);
    datesReference.current = clone(sortedDates);
    // setWaitlistData(newWaitlistDataCopy);
    // setWaitlistDataCopy(newWaitlistDataCopy);
  };

  const handleSave = (e) => {
    console.log('handleSave firing ');
    e.preventDefault();
    const payload = {};
    dates.forEach((item) => {
      const {
        ChosenByCandidate,
        PreferenceOrder,
        entryState,
        Removed,
        OriginalRemoved,
        OriginalPreferenceOrder,
        isWaitlistItem,
        pk_Candidate,
        pk_InterviewDate,
        UUID,
      } = item;

      // if (!isWaitlistItem) {
      //   return;
      // }
      payload[pk_InterviewDate] = {
        PreferenceOrder: PreferenceOrder != null ? PreferenceOrder : null,
        Removed: Removed ? Removed : false,
        fk_Candidate: selectedCandidate.pk_Candidate,
        fk_InterviewDate: pk_InterviewDate,
      };
    });

    // return;
    // setLoading(true);
    // TODO: Crash on save without edits might be because no manual assignmetn of PreferenceOrder.
    // Jeff said new endpoint would have those.
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/candidate/waitlist',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_Candidate: selectedCandidate.pk_Candidate,
          },
          { payload },
          formatBearerToken(token),
        )
          .then((result) => {
            setLoading(false);
            alert.success('Edits saved.');
            const newChanges = clone(changes);
            newChanges.waitlist = false;
            setChanges(newChanges);
            setIsWaitlisted(result.data ? result.data.isWaitlisted : false);
            getCandidateDetails(selectedCandidate);
            // saveResults({
            //   candidatesToUpdate: [selectedCandidate.pk_Candidate],
            //   fieldsToUpdate: {},
            //   forcedSelectedCandidateId: selectedCandidate.pk_Candidate,
            //   callback: (success) => {
            //     if (success) {
            //     }
            //   },
            // });
          })
          .catch((err) => {
            setLoading(false);
            alert.error('Error when saving.');
          })
          .finally(() => {
            getInterviewDates(null, selectedCandidate);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const renderInterviewDates = () => {
    return (
      <>
        {dates.map((item, i) => {
          // const { pk_InterviewDate } = item;
          const isCandidateRegisteredDate =
            selectedCandidate && item.pk_InterviewDate == selectedCandidate.pk_InterviewDate;
          const { History, PreferenceOrder, Removed, pk_InterviewDate, UUID, isWaitlistItem } = item;

          const hasWaitlistData = isWaitlistItem && !Removed;

          const isRemovedItem = isWaitlistItem && Removed;

          let backgroundColor = i % 2 == 0 ? '#c7cbd1' : null;

          return (
            <div style={{ ...style.interviewDateItem, backgroundColor }} key={`itemKey_${item.pk_InterviewDate}`}>
              <div style={style.simpleColumn} id={`date_${pk_InterviewDate}`}>
                <Button
                  color={!hasWaitlistData ? 'success' : 'danger'}
                  disabled={isCandidateRegisteredDate}
                  onClick={() => {
                    if (hasWaitlistData && !isRemovedItem) {
                      removeDateFromWaitlist(pk_InterviewDate);
                    } else {
                      addDateToWaitlist(pk_InterviewDate || UUID);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={!hasWaitlistData ? faPlus : faMinus} />
                </Button>
                {isCandidateRegisteredDate ? (
                  <UncontrolledTooltip target={`date_${pk_InterviewDate}`}>
                    Candidate is registered for this session.
                  </UncontrolledTooltip>
                ) : null}
              </div>
              {!hasWaitlistData ? (
                <div style={{ ...style.simpleColumn, width: 150 }}></div>
              ) : (
                <div style={{ ...style.simpleColumn, width: 150 }}>
                  <Input
                    value={PreferenceOrder != i ? i + 1 : PreferenceOrder + 1}
                    id={`sortOrder_${item.pk_InterviewDate}`}
                    type="select"
                    style={{ textAlign: 'center' }}
                    onChange={(e) => {
                      changeSortOrder(e.target.value, pk_InterviewDate || UUID);
                    }}
                  >
                    {dates
                      .filter((item) => {
                        return !item.Removed && item.isWaitlistItem;
                      })
                      .map((key, i) => {
                        const item = datesReference[key];
                        return (
                          <option key={i} value={i + 1}>
                            {i + 1}
                          </option>
                        );
                      })}
                  </Input>
                </div>
              )}

              {hasWaitlistData && i != PreferenceOrder ? (
                <UncontrolledTooltip target={`sortOrder_${item.pk_InterviewDate}`}>
                  Was choice {PreferenceOrder + 1}, but some previously selected preferences are no longer available
                </UncontrolledTooltip>
              ) : null}

              <div style={{ ...style.simpleColumn, width: '60%' }}>
                <div style={{ textAlign: 'center' }}>{moment(item.DateOfInterview).format('dddd, MMM. DD, YYYY')}</div>

                <div style={{ textAlign: 'center', fontSize: 12, fontWeight: 'bold' }}>
                  {item.Label || '(No Label)'}
                </div>
              </div>

              <div style={style.simpleColumn}>
                <Button
                  color="primary"
                  id={`info_${item.pk_InterviewDate}`}
                  disabled={!History || History.length === 0}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <UncontrolledPopover trigger="legacy" target={`info_${item.pk_InterviewDate}`}>
                    <PopoverHeader>Details</PopoverHeader>
                    <PopoverBody>
                      <div style={{ height: 300, overflowY: 'scroll', width: 250 }}>{renderHistory(History)}</div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </Button>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  if (!dates) {
    console.log('NO DATES ');
  }
  const hasWaitlistData = dates && dates.length > 0;

  return (
    <div style={style.simpleRow}>
      {loading && (
        <div class="loading_overlay">
          <div></div>
        </div>
      )}
      <div style={{ ...style.simpleColumn, width: '100%' }}>
        <div
          style={{
            ...style.simpleRow,
            fontSize: 20,
            fontWeight: 'bold',
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: 'white',
          }}
        >
          Manage Waitlist
        </div>
        <div style={{ ...style.interviewDateItem, position: 'sticky', top: 28, backgroundColor: 'white', zIndex: 10 }}>
          <div style={{ ...style.simpleColumn, visibility: 'hidden' }}>
            <Button onClick={() => {}}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <div style={{ ...style.simpleColumn, width: 150, textAlign: 'center', fontWeight: 'bold' }}>
            Preference Order
          </div>

          <div style={{ ...style.simpleColumn, width: '60%', textAlign: 'center', fontWeight: 'bold' }}>
            Interview Session
          </div>
          <div>
            <Button
              // disabled={JSON.stringify(waitlistDataCopy) === JSON.stringify(waitlistData)}
              disabled={!changes.waitlist}
              onClick={handleSave}
              color="success"
            >
              Save Changes
            </Button>
          </div>
        </div>
        <div>
          <div style={{ ...style.simpleColumn }}>
            {hasWaitlistData ? (
              renderInterviewDates()
            ) : (
              <div style={{ alignItems: 'center', minHeight: 200, display: 'flex', justifyContent: 'center' }}>
                There are no available interview sessions for this candidate.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
