/*global structuredClone, a*/
import { name, version } from '../package.json';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useContext, useEffect, useState } from 'react';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
// import EvaluatorRedirect from './Body/RezRATE/EvaluateRedirect'
import './App.css';
import AuthWrapperContainer from './auth0/AuthWrapper.container';
import DocumentViewer from './Body/DocumentViewer/DocumentViewer';
import CandidatePhoto from './Body/DragAndDropFiles/CandidatePhoto.container';
import DragAndDropFiles from './Body/DragAndDropFiles/DragAndDropFiles';
// import OrganizationCreation from './Body/OrganizationCreation/OrganizationCreation';
// import NewOrganization from './Body/NewOrganization/NewDepartment/NewDepartment';
import EntityWrapper from './Body/CreateNewEntities/EntityWapper';
import FileViewer from './Body/DragAndDropFiles/FileViewer';
import Footer from './Body/Footer';
import FourOhFour from './Body/FourOhFour';
import InterviewDatesRouterContianer from './Body/InterviewDates/InterviewDateRouter.container';
import InterviewScheduleContainer from './Body/InterviewDates/InterviewDatesSteps/InterviewSchedule.container';
import { CustomCard, CustomCol } from './Body/InterviewDates/InterviewDatesWrapper.styles';
import ZoomRoom from './Body/InterviewDates/ZoomRoom';
import EvalTokenSetterContainer from './Body/Login/EvalTokenSetter.container';
import CandidateSubscription from './Body/CandidateSubscription/CandidateSubscription.container';
import ForgotPasswordContainer from './Body/Login/ForgotPassword.container';
import RedirectLogin from './Body/RedirectLogin/RedirectLogin';
import LoginContainer from './Body/Login/Login.container';
import ResetContainer from './Body/Login/Reset.container';
import NotificationTest from './Body/Notifications/NotificationTest';
import EvaluatorLandingContainer from './Body/RezRATE/AdminLanding/AdminLanding.container';
import CoordinatorSchedule from './Body/RezRATE/Coordinator/CoordinatorSchedule';
import AgreementDocumentContainer from './Body/RezRATE/Header/MobileForkEvaluator/AgreementDocumentContainer';
import RezRATE from './Body/RezRATE/RezRATE.container';
import Wrapper from './Body/RezRATE/Wrapper';
import SeasonChanger from './Body/SeasonChanger';
import SubscriptionForkContainer from './Body/Subscription/SubscriptionFork.container';
import Test from './Body/Test/Test';
import { DepartmentContext, DepartmentWrapper } from './DepartmentWrapper';
import LayoutContainer from './Layout.container';
import { fetchDataAgnostic } from './Services/dataApi';
import { compareVersionStrings } from './Common.functions';
import moment from 'moment-timezone';
import AdminSuperUserPanel from './Body/RezRATE/AdminLanding/AdminSuperUserPanel/AdminSuperUserPanel';
import HealthCheck from 'Body/HealthCheck';
import AttachmentViewer from 'Body/RezRATE/AdminLanding/AdminCommunications/AttachmentViewer/AttachmentViewer';
import DirectApplication from 'Body/RezRATE/Body/DirectApplication/Directapplication';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import AdminLogout from 'Body/RezRATE/AdminLanding/AdminLogout/AdminLogout';
import { useAuth0 } from 'auth0/reactAuth0Spa';

const OutterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: '100%';
  min-height: 100%;
  background-color: '#000000';
`;

const fakeAuth = {
  isAthenticated: true,
};
// const checkAuth = () => {
//   const token = localStorage.getItem('token');
//   const refreshToken = localStorage.getItem('refreshToken');

//   if (!token || !refreshToken) {
//     return false;
//   }

//   try {
//     // { exp: 12903819203 }
//     const { exp } = decode(refreshToken);

//     if (exp < new Date().getTime() / 1000) {
//       return false;
//     }
//   } catch (e) {
//     return false;
//   }

//   return true;
// };

// const AuthRoute = ({ component: Component, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         checkAuth() ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathanme: './login',
//               state: { from: props.location },
//             }}
//           />
//         )
//       }
//     />
//   );
// };

const App = () => {
  const [embedded, setEmbedded] = useState(null);

  const { isAuthenticated, loading } = useAuth0();

  const toggleEmbedded = () => {
    const { href } = window.location;
    if (href.toUpperCase().includes('EMBEDDED=1')) {
      setEmbedded(true);
    } else {
      setEmbedded(false);
    }
  };

  useEffect(() => {
    checkVersion();
    toggleEmbedded();
    window.addEventListener(
      'hashchange',
      function() {
        toggleEmbedded();
      },
      false,
    );
  }, []);

  const checkVersion = () => {
    const now = moment();
    fetchDataAgnostic('app/status')
      .then((res) => {
        const serverVersion = res.data ? res.data.AppVersion : '';
        const isOutDated = compareVersionStrings(serverVersion, version);
        const lastBusted = localStorage.getItem('lastBusted');
        const lastBustedTime = lastBusted ? moment(lastBusted) : moment.now(); //'Jul 27, 2022 13:00';

        if (isOutDated && (!lastBustedTime || now.diff(lastBustedTime, 'hours') >= 8)) {
          clearCacheAndRefresh();
        } else if (!lastBustedTime) {
          localStorage.setItem('lastBusted', now.toISOString());
        } else {
        }
      })
      .catch((err) => {});
  };

  const clearCacheAndRefresh = () => {
    if (caches) {
      caches.keys().then((res) => {
        res.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    const now = moment();
    localStorage.setItem('lastBusted', now.format('MMM DD, YYYY HH:mm'));
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  if (embedded === null) return <div />;

  return (
    <Router
      className=" three"
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <OutterWrapper>
        <div
          className="two"
          id="scroll_wrapper"
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'white',
            width: '100%',
            height: 'calc(100vh - 50px)',
            overflowY: 'scroll',
          }}
        >
          <NotificationContainer />

          <Switch>
            <Route path="/candidate/photo/:id" component={CandidatePhoto} />
            <Route path="/candidate/email/subscription/:candidateId" component={CandidateSubscription} />
            <Route path="/candidate/schedule/:id" component={InterviewScheduleContainer} />
            <Route path="/candidate/:id" component={InterviewDatesRouterContianer} />
            <Route path="/department/attachment/:uuid" component={AttachmentViewer} />
            <Route path="/notifications/test" component={NotificationTest} />
            <Route path="/system/status/app" component={HealthCheck} />

            <Route
              path="/applicant"
              render={() => {
                return (
                  <KindeProvider
                    clientId="4f92eb0f947c4f869fd65260d2c5ff1d"
                    // clientId="b3dfe52b97d542788111405acb5fc09a"
                    domain="https://rezrate-dev.us.kinde.com"
                    // domain="https://devauth.rezrate.com/"
                    logoutUri={`${window.location.origin}/applicant`}
                    redirectUri={`${window.location.origin}/applicant/profile`}
                  >
                    <DirectApplication myKey={'outside'} />
                  </KindeProvider>
                );
              }}
            />
            <Route
              path="/"
              render={(props) => {
                const { location } = props;
                const { pathname } = props.location;

                // Handles /logout
                if (pathname === '/logout' && !isAuthenticated && !loading) {
                  return <AdminLogout />;
                }
                return (
                  <AuthWrapperContainer>
                    <DepartmentWrapper>
                      <LayoutContainer embedded={embedded}>
                        <CustomCol key={2} sm={12} className="">
                          <CustomCard style={{ border: 'none' }} className="">
                            <SeasonChanger>
                              <Switch>
                                <Route exact path="/" component={LoginContainer} />
                                <Route path="/logout" component={AdminLogout} />
                                <Route path="/user/login/:email" component={RedirectLogin} />

                                <Route exact path="/organizations" component={EntityWrapper} />
                                <Route
                                  path="/viewFile/:pk_Candidate/:pk_File/:pk_DocumentType/:fileName"
                                  component={FileViewer}
                                />
                                <Route path="/login/:id" component={EvalTokenSetterContainer} />
                                <Route path="/zoom" component={ZoomRoom} />
                                <Route path="/reset/:id" component={ResetContainer} />
                                <Route path="/reset" component={ForgotPasswordContainer} />
                                <Route path="/unsubscribe/:id" component={SubscriptionForkContainer} />
                                <Route path="/agreement" component={AgreementDocumentContainer} />
                                <Route exact path="/evaluate" component={Wrapper}></Route>

                                <Route path="/administrator" component={EvaluatorLandingContainer} />
                                <Route path="/user/evaluate/:pk_InterviewDate" component={RezRATE}></Route>

                                <Route path="/e0b6c3c4-c758-4747-8c1f-1248597713f2" component={Test} />
                                <Route
                                  path="/files/view/:organization/:department/:seasonID/:pk_UUID_FM/:tempToken"
                                  render={(props) => <DocumentViewer {...props} embedded={embedded} />}
                                />
                                <Route
                                  path="/files/:organization/:department/:seasonID/:pk_UUID_FM/:tempToken"
                                  render={(props) => <DragAndDropFiles {...props} embedded={embedded} />}
                                />
                                <Route
                                  path="/logo/:organization/:department/:tempToken"
                                  render={(props) => (
                                    <DragAndDropFiles {...props} uploadLogo={true} embedded={embedded} />
                                  )}
                                />

                                <Route
                                  render={() => {
                                    return <FourOhFour myKey={'inside'} isAuthenticated={true} />;
                                  }}
                                />
                              </Switch>
                            </SeasonChanger>
                          </CustomCard>
                        </CustomCol>
                      </LayoutContainer>
                    </DepartmentWrapper>
                  </AuthWrapperContainer>
                );
              }}
            ></Route>

            <Route
              render={() => {
                return <FourOhFour myKey={'outside'} />;
              }}
            />
          </Switch>
        </div>
        <Footer />
      </OutterWrapper>
    </Router>
  );
};

export default App;

// :authority: tools.rezrate.com
// :method: GET
// :path: /Streaming_SSL/Additional_1/99600DCAB3C04EB2CAEF37451B8449DD482521A9AA2FB81471CE976385DFCF76.jpg?RCType=EmbeddedRCFileProcessor
// :scheme: https
// accept: text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9
// accept-encoding: gzip, deflate, br
// accept-language: en-US,en;q=0.9
// cache-control: no-cache
// cookie: X-FMS-Session-Key=4FFC1084F8A5FF568B376DAFFA458012A21E404F6DF409BEB5E3E7B854E7F316
// pragma: no-cache
// sec-fetch-dest: document
// sec-fetch-mode: navigate
// sec-fetch-site: none
// sec-fetch-user: ?1
// upgrade-insecure-requests: 1
// user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.132 Safari/537.36
